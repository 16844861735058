var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "group_aside_search_page" }, [
    _vm.no_data
      ? _c("div", { staticClass: "no_data" }, [_vm._v("没有找到相关结果")])
      : _c("div", { staticClass: "search_status" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.search_friends && _vm.search_friends.length,
                  expression: "search_friends && search_friends.length",
                },
              ],
              staticClass: "contact_group group",
            },
            [
              _c(
                "div",
                {
                  staticClass: "title d-flex align-center cp un_sel",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      _vm.openFriends = !_vm.openFriends
                    },
                  },
                },
                [
                  _c("div", {
                    staticClass: "icon mr-5",
                    class: { active: _vm.openFriends },
                  }),
                  _c("div", { staticClass: "title_name" }, [
                    _vm._v("\n          联系人\n        "),
                  ]),
                ]
              ),
              _vm.openFriends
                ? [
                    _vm._l(_vm.search_friends, function (item, index) {
                      return [
                        index < 3
                          ? _c("contact-unit", {
                              key: item.id,
                              staticClass: "cp un_sel",
                              attrs: {
                                stick: "0",
                                messageData: item,
                                search: true,
                                no_border: true,
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.goSingleDialog(item)
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "name",
                                    fn: function () {
                                      return [
                                        _vm.highlightKeywords(
                                          item.friendRemark,
                                          _vm.search_key
                                        ) !== item.friendRemark
                                          ? _c(
                                              "span",
                                              {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.highlightKeywords(
                                                      item.friendRemark,
                                                      _vm.search_key
                                                    )
                                                  ),
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                name1\n              "
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "span",
                                              {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.highlightKeywords(
                                                      item.userName,
                                                      _vm.search_key
                                                    )
                                                  ),
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                name1\n              "
                                                ),
                                              ]
                                            ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            })
                          : _vm._e(),
                      ]
                    }),
                    _vm.switch_contact
                      ? [
                          _vm._l(_vm.search_friends, function (item, index) {
                            return [
                              index >= 3
                                ? _c("contact-unit", {
                                    key: item.id,
                                    staticClass: "cp un_sel",
                                    attrs: {
                                      stick: "0",
                                      messageData: item,
                                      search: true,
                                      no_border: true,
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.goSingleDialog(item)
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "name",
                                          fn: function () {
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.highlightKeywords(
                                                        item.friendRemark,
                                                        _vm.search_key
                                                      )
                                                    ),
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  name1\n                "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.highlightKeywords(
                                                        item.userName,
                                                        _vm.search_key
                                                      )
                                                    ),
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  name1\n                "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  })
                                : _vm._e(),
                            ]
                          }),
                        ]
                      : _vm._e(),
                    _c("div", { staticClass: "fun_group" }, [
                      _c(
                        "div",
                        {
                          staticClass: "box",
                          class: {
                            only_border: _vm.search_friends.length < 3,
                            on: _vm.switch_contact,
                          },
                          on: {
                            click: function ($event) {
                              _vm.switch_contact = !_vm.switch_contact
                            },
                          },
                        },
                        [
                          _vm.search_friends.length > 3
                            ? _c("div", { staticClass: "icon cp" })
                            : _vm._e(),
                          _vm.search_friends.length > 3
                            ? _c("div", { staticClass: "text cp" }, [
                                _vm._v(
                                  _vm._s(_vm.switch_contact ? "收起" : "展开") +
                                    "(" +
                                    _vm._s(_vm.search_friends.length) +
                                    ")"
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ]),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.search_chat_groups && _vm.search_chat_groups.length,
                  expression: "search_chat_groups && search_chat_groups.length",
                },
              ],
              staticClass: "work_team_group group",
            },
            [
              _c(
                "div",
                {
                  staticClass: "title d-flex align-center cp un_sel",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      _vm.openChatGroup = !_vm.openChatGroup
                    },
                  },
                },
                [
                  _c("div", {
                    staticClass: "icon mr-5",
                    class: { active: _vm.openChatGroup },
                  }),
                  _c("div", { staticClass: "title_name" }, [
                    _vm._v("\n          工作组\n        "),
                  ]),
                ]
              ),
              _vm.openChatGroup
                ? [
                    _vm._l(_vm.search_chat_groups, function (item, index) {
                      return [
                        index < 3
                          ? _c("work-team-unit", {
                              key: item.id,
                              staticClass: "cp un_sel",
                              attrs: {
                                userlist: item.pictures,
                                stick: "0",
                                item: item,
                                messageData: item,
                                search: true,
                                no_border: true,
                              },
                              nativeOn: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.goGroupDialog(item)
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "name",
                                    fn: function () {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.highlightKeywords(
                                                  item.chatName,
                                                  _vm.search_key
                                                )
                                              ),
                                            },
                                          },
                                          [_vm._v("name1")]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                  {
                                    key: "desc",
                                    fn: function () {
                                      return [
                                        item.secondleveContent != null
                                          ? _c("span", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.highlightKeywords(
                                                    `成员: ${item.secondleveContent}`,
                                                    _vm.search_key
                                                  )
                                                ),
                                              },
                                            })
                                          : _vm._e(),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            })
                          : _vm._e(),
                      ]
                    }),
                    _vm.switch_workteam
                      ? [
                          _vm._l(
                            _vm.search_chat_groups,
                            function (item, index) {
                              return [
                                index >= 3
                                  ? _c("work-team-unit", {
                                      key: item.id,
                                      staticClass: "cp un_sel",
                                      attrs: {
                                        userlist: item.pictures,
                                        stick: "0",
                                        item: item,
                                        messageData: item,
                                        search: true,
                                        no_border: true,
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.goGroupDialog(item)
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "name",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "span",
                                                  {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.highlightKeywords(
                                                          item.chatName,
                                                          _vm.search_key
                                                        )
                                                      ),
                                                    },
                                                  },
                                                  [_vm._v("name1")]
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                          {
                                            key: "desc",
                                            fn: function () {
                                              return [
                                                item.secondleveContent != null
                                                  ? _c("span", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          _vm.highlightKeywords(
                                                            `成员: ${item.secondleveContent}`,
                                                            _vm.search_key
                                                          )
                                                        ),
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  : _vm._e(),
                              ]
                            }
                          ),
                        ]
                      : _vm._e(),
                    _c("div", { staticClass: "fun_group un_sel" }, [
                      _c(
                        "div",
                        {
                          staticClass: "box",
                          class: {
                            only_border: _vm.search_chat_groups.length < 3,
                            on: _vm.switch_workteam,
                          },
                          on: {
                            click: function ($event) {
                              _vm.switch_workteam = !_vm.switch_workteam
                            },
                          },
                        },
                        [
                          _vm.search_chat_groups.length > 3
                            ? _c("div", { staticClass: "icon cp" })
                            : _vm._e(),
                          _vm.search_chat_groups.length > 3
                            ? _c("div", { staticClass: "text cp" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.switch_workteam ? "收起" : "展开"
                                  ) +
                                    "(" +
                                    _vm._s(_vm.search_chat_groups.length) +
                                    ")"
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ]),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "group template_group" },
            [
              _c(
                "div",
                {
                  staticClass: "title d-flex align-center cp un_sel",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.switchOpenTask.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", {
                    staticClass: "icon mr-5",
                    class: { active: _vm.openTask },
                  }),
                  _c("div", { staticClass: "title_name" }, [
                    _vm._v("\n          任务\n        "),
                  ]),
                ]
              ),
              _vm.search_task && _vm.search_task.length && _vm.openTask
                ? _c(
                    "div",
                    { staticClass: "template_list" },
                    [
                      _vm._l(_vm.search_task, function (item, index) {
                        return [
                          _c(
                            "div",
                            {
                              key: index,
                              staticClass: "template_unit cp",
                              on: {
                                click: function ($event) {
                                  return _vm.doOpen(item)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "task_group" }, [
                                item.colour
                                  ? _c(
                                      "div",
                                      { staticClass: "color_group" },
                                      _vm._l(
                                        _vm.getColor(item.colour),
                                        function (item, index) {
                                          return _c("div", {
                                            key: index,
                                            staticClass: "color",
                                            style: { "background-color": item },
                                          })
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticClass: "info_group" },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: item.name,
                                          placement: "bottom-start",
                                          "open-delay": 600,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "task_name" },
                                          [_vm._v(_vm._s(item.name))]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "from_group" },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              effect: "dark",
                                              content: item.createusername,
                                              placement: "bottom-start",
                                              "open-delay": 600,
                                            },
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "avatar",
                                              attrs: {
                                                src: `${_vm.$avatar_url}${item.createuserthumbnail}@!small200`,
                                                alt: "",
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              effect: "dark",
                                              content: item.from,
                                              placement: "bottom-start",
                                              "open-delay": 600,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "chat_name" },
                                              [
                                                _vm._v(
                                                  "来源：" + _vm._s(item.from)
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                        ]
                      }),
                      _vm.switch_task && 0
                        ? [
                            _vm._l(_vm.search_task, function (item, index) {
                              return [
                                index >= 3
                                  ? _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "template_unit cp",
                                        on: {
                                          click: function ($event) {
                                            return _vm.doOpen(item)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "task_group" },
                                          [
                                            item.colour
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "color_group",
                                                  },
                                                  _vm._l(
                                                    _vm.getColor(item.colour),
                                                    function (item, index) {
                                                      return _c("div", {
                                                        key: index,
                                                        staticClass: "color",
                                                        style: {
                                                          "background-color":
                                                            item,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              { staticClass: "info_group" },
                                              [
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass: "item",
                                                    attrs: {
                                                      effect: "dark",
                                                      content: item.name,
                                                      placement: "bottom-start",
                                                      "open-delay": 600,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "task_name",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.name)
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "from_group" },
                                                  [
                                                    _c(
                                                      "el-tooltip",
                                                      {
                                                        staticClass: "item",
                                                        attrs: {
                                                          effect: "dark",
                                                          content:
                                                            item.createusername,
                                                          placement:
                                                            "bottom-start",
                                                          "open-delay": 600,
                                                        },
                                                      },
                                                      [
                                                        _c("img", {
                                                          staticClass: "avatar",
                                                          attrs: {
                                                            src: `${_vm.$avatar_url}${item.createuserthumbnail}@!small200`,
                                                            alt: "",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _c(
                                                      "el-tooltip",
                                                      {
                                                        staticClass: "item",
                                                        attrs: {
                                                          effect: "dark",
                                                          content: item.from,
                                                          placement:
                                                            "bottom-start",
                                                          "open-delay": 600,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "chat_name",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "来源：" +
                                                                _vm._s(
                                                                  item.from
                                                                )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            }),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.loadingTask
                ? [_c("loading-icon", { attrs: { size: "20px" } })]
                : _vm._e(),
              _vm.search_task && _vm.search_task.length && _vm.openTask
                ? _c(
                    "div",
                    { staticClass: "fun_group" },
                    [
                      0
                        ? [
                            !_vm.switch_task
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "box",
                                    class: {
                                      only_border: _vm.search_task.length < 3,
                                      on: _vm.switch_task,
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.switch_task = !_vm.switch_task
                                      },
                                    },
                                  },
                                  [
                                    _vm.search_task.length > 3
                                      ? _c("div", { staticClass: "icon cp" })
                                      : _vm._e(),
                                    _vm.search_task.length > 3
                                      ? _c("div", { staticClass: "text cp" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.switch_task ? "收起" : "展开"
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        : _vm._e(),
                      !_vm.no_more_task &&
                      _vm.search_task &&
                      _vm.search_task.length >= 20 &&
                      !_vm.loadingTask
                        ? _c(
                            "div",
                            {
                              staticClass: "box un_sel",
                              on: {
                                click: function ($event) {
                                  return _vm.getMoreSearchTask()
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "icon cp" }),
                              _c("div", { staticClass: "text cp" }, [
                                _vm._v("加载更多"),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            2
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadChatGroupUser,
                  expression: "loadChatGroupUser",
                },
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.search_chat_groups && _vm.search_chat_groups.length,
                  expression: "search_chat_groups && search_chat_groups.length",
                },
              ],
              staticClass: "work_team_group group",
            },
            [
              _c(
                "div",
                {
                  staticClass: "title d-flex align-center cp un_sel",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.switchOpenChatGroupUser.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", {
                    staticClass: "icon mr-5",
                    class: { active: _vm.openChatGroupUser },
                  }),
                  _c("div", { staticClass: "title_name" }, [
                    _vm._v("\n          工作组成员\n        "),
                  ]),
                ]
              ),
              _vm.openChatGroupUser
                ? [
                    _vm._l(_vm.chatGroupUser, function (item) {
                      return [
                        _c("work-team-unit", {
                          key: item.id,
                          staticClass: "cp un_sel",
                          attrs: {
                            userlist: item.pictures,
                            stick: "0",
                            item: item,
                            messageData: item,
                            search: true,
                            no_border: true,
                          },
                          nativeOn: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.goGroupDialog(item)
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "name",
                                fn: function () {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.highlightKeywords(
                                              item.chatName,
                                              _vm.search_key
                                            )
                                          ),
                                        },
                                      },
                                      [_vm._v("name1")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "desc",
                                fn: function () {
                                  return [
                                    item.secondleveContent != null
                                      ? _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.highlightKeywords(
                                                `成员: ${item.secondleveContent}`,
                                                _vm.search_key
                                              )
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ]
                    }),
                  ]
                : _vm._e(),
            ],
            2
          ),
          0
            ? _c("div", { staticClass: "group other_group" }, [
                _c("div", { staticClass: "title" }, [_vm._v("模板")]),
                _vm._m(0),
                _vm._m(1),
              ])
            : _vm._e(),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "other_content" }, [
      _c("img", {
        staticClass: "img",
        attrs: {
          src: require("@/assets/images/common/task_search_other.png"),
          alt: "",
        },
      }),
      _c("div", { staticClass: "info_group" }, [
        _c("div", { staticClass: "info_title" }, [_vm._v("搜索模板记录")]),
        _c("div", { staticClass: "info_subtitle" }, [
          _vm._v("查找(包含'1391')的模板"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "fun_group" }, [
      _c("div", { staticClass: "box" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }