var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "work_team_unit_wrapper",
      class: {
        on:
          _vm.chat_id == _vm.messageData.chatId &&
          _vm.messageData.chatType == "1" &&
          _vm.$route.name == "fun_task_dialog_group",
        top: _vm.messageData.istop,
      },
    },
    [
      0
        ? _c("img", {
            staticClass: "sticky",
            attrs: { src: require("@/assets/label-Sticky.png"), alt: "" },
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "img_group",
          on: {
            click: function ($event) {
              _vm.visible = false
            },
          },
        },
        [
          _c("img", {
            staticClass: "img",
            attrs: {
              src: `${_vm.$avatar_url}${_vm.item.photo}@!small200`,
              alt: "",
            },
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.messageData &&
                    !_vm.messageData.isdisturb &&
                    _vm.messageData.unreadMessageNum &&
                    _vm.messageData.unreadMessageNum != 0,
                  expression:
                    "messageData && !messageData.isdisturb && messageData.unreadMessageNum &&messageData.unreadMessageNum != 0",
                },
              ],
              staticClass: "icon",
            },
            [_vm._v(_vm._s(_vm._f("unread")(_vm.messageData.unreadMessageNum)))]
          ),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.messageData &&
                  _vm.messageData.isdisturb &&
                  _vm.messageData.unreadMessageNum &&
                  _vm.messageData.unreadMessageNum != 0,
                expression:
                  "messageData && messageData.isdisturb && messageData.unreadMessageNum && messageData.unreadMessageNum != 0",
              },
            ],
            staticClass: "icon do_not_distrub",
          }),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "info_group ml-6",
          class: {
            search: _vm.search,
            no_border: _vm.messageData.istop || _vm.no_border,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "name_group",
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "name pr-4" },
                [
                  _vm._t("name", function () {
                    return [_vm._v(" ")]
                  }),
                ],
                2
              ),
              _c("div", { staticClass: "time" }, [_vm._t("time")], 2),
            ]
          ),
          _c(
            "div",
            { staticClass: "desc_group" },
            [
              _c(
                "div",
                {
                  staticClass: "desc",
                  on: {
                    click: function ($event) {
                      _vm.visible = false
                    },
                  },
                },
                [
                  _vm._t("desc", function () {
                    return [_vm._v(" ")]
                  }),
                ],
                2
              ),
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.messageData && _vm.messageData.isdisturb,
                    expression: "messageData && messageData.isdisturb",
                  },
                ],
                staticClass: "do_not_distrub flex-shrink-0",
              }),
              !_vm.no_border
                ? _c(
                    "el-popover",
                    {
                      attrs: {
                        "popper-class":
                          "sidebar_contact_unit_popover_wrapper_el",
                        placement: "right-start",
                        trigger: "click",
                        "visible-arrow": false,
                      },
                      on: { show: _vm.popoverShow },
                      model: {
                        value: _vm.visible,
                        callback: function ($$v) {
                          _vm.visible = $$v
                        },
                        expression: "visible",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "sidebar_contact_unit_popover_wrapper" },
                        [
                          _c("div", { staticClass: "fun_floor_group" }, [
                            _c(
                              "div",
                              {
                                staticClass: "fun cp",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.messageData && _vm.messageData.isdisturb
                                      ? "允许消息通知"
                                      : "消息免打扰"
                                  ),
                                },
                                on: { click: _vm.changeDoNotDistrub },
                              },
                              [_vm._v("取消置顶")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "fun cp",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.messageData.istop ? "取消置顶" : "置顶"
                                  ),
                                },
                                on: { click: _vm.setMessageTop },
                              },
                              [_vm._v("取消置顶")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "fun cp",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.doDelMessageBox(_vm.item)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "content",
                          attrs: { slot: "reference" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.$api.stopPropagation($event)
                            },
                          },
                          slot: "reference",
                        },
                        [_c("div", { staticClass: "fun cp" })]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }