<!--
 * @Author: your name
 * @Date: 2020-05-20 14:15:49
 * @LastEditTime : 2022-01-05 11:33:53
 * @LastEditors  : Hugo
 * @Description: In User Settings Edit
 * @FilePath     : /miaohang/src/components/sidebar/contactUnit.vue
--> 


<!-- 联系人 -->
<template>
  <div
    class="contact_unit_wrapper"
    :class="{on: ( (chat_id == messageData.chatId && messageData.chatType == '0' && $route.name == 'fun_task_dialog_single') && !search), top: messageData.istop}"

  >
    <img src="@/assets/label-Sticky.png" alt="" class="sticky" v-if="0">
    <div class="img_group" @click="visible=false;">
      <div
        class="icon"
        v-show="messageData && !messageData.isdisturb && messageData.unreadMessageNum && messageData.unreadMessageNum != 0"
      >{{messageData.unreadMessageNum | unread}}</div>
      <div
        class="icon do_not_distrub"
        v-show="messageData && messageData.isdisturb && messageData.unreadMessageNum && messageData.unreadMessageNum != 0"
      ></div>
      <!-- <img :src="`${$avatar_url}${messageData.pictures[0]}`" alt="" class="img"> -->
      <img 
        :src="`${$avatar_url}${messageData.photo}@!small200`" 
        v-if="!search && messageData.photo" 
        alt="" 
        class="img"
      >
      <img 
        :src="`${$avatar_url}${messageData.thumbnail}@!small200`" 
        v-else-if="search && messageData.thumbnail" 
        class="img" 
        alt=""
      >
      <img
        v-else
        :src="require(`@/assets/${$default_avatar}`)"
        alt=""
        class="img" 
      >
    </div>
    <div class="info_group ml-6" :class="{search, no_border: messageData.istop || no_border}">
      <div class="name_group" @click="visible=false;">
        <!-- 姓名 -->
        <div class="name pr-4">
          <slot name="name">&nbsp;</slot>
        </div>
        <div class="time">
          <slot name="time">&nbsp;</slot>
        </div>
        <!-- <div class="time">{{messageData.lastSendTime | formatDate('HH:mm')}}</div> -->
      </div>
      <div class="desc_group" v-if="!search">
        <div class="desc" @click="visible=false;">
          <slot name="desc">&nbsp;</slot>
        </div>
        
        <!-- <div class="desc">按照VIVO的KV，开始...</div> -->
        <div class="do_not_distrub flex-shrink-0"
          v-show="messageData && messageData.isdisturb"
        ></div>
        <el-popover
          popper-class="sidebar_contact_unit_popover_wrapper_el"
          placement="right-start"
          trigger="click"
          :visible-arrow="false"
          v-model="visible"
          @show="popoverShow"
          v-if="1"
        > 
          <div class="sidebar_contact_unit_popover_wrapper" >
            <div class="fun_floor_group">
              <div
                class="fun cp"
                v-html="messageData && messageData.isdisturb ? '允许消息通知' : '消息免打扰'"
                @click="changeDoNotDistrub"
              >取消置顶</div>
              <div
                class="fun cp"
                v-html="messageData.istop ? '取消置顶' : '置顶'"
                @click="setMessageTop"
              >取消置顶</div>
              <div
                class="fun cp"
                @click.stop="doDelMessageBox(messageData)"
              >删除</div>
            </div>
          </div>
          <div class="content" slot="reference"  
            @click.stop="$api.stopPropagation($event)"
          >
           
            <div class="fun cp"></div>
          </div>
          
        </el-popover>    
      </div>


    </div>
    
  </div>
</template>
<script>
export default {
  props:{
    stick: {
      type: String,
      default: '0',
    },
    messageData: {
      type: Object,
      default: null,
    },
     // 搜索？
    search:{
      type: Boolean,
      default: false,
    },
    no_border: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return{
      visible: false,
    }
  },
  computed: {
    // 联系人置顶功能id
    contact_stick_fun_id(){
      return this.$store.state.task_module.contact_stick_fun_id;
    },
     // 当前会话id
    chat_id(){
      return this.$store.state.chat_id;
    },
  },
  watch: {
    contact_stick_fun_id: function(newVal){
      if(newVal != this.messageData.chatId){
        // 关闭未激活的联系人
        this.visible = false;
      }
    },
    chat_id: function(){},
    search: function(){},
  },
  filters:{
    unread(num){
      if(num>99){
        return '99+';
      }
      return num;
    }
  },
  mounted: function () {
  },
  methods:{
    popoverShow(){
      this.$store.commit('set_contact_stick_fun_id', this.messageData.chatId)
    },
    /**
     * @description: 删除消息盒子
     */
    async doDelMessageBox(item){

      this.$openConfirm({
        title:'',
        desc:'是否从最近沟通列表删除',
        sure:'是',
        cancel:'否',
        onSure: async ()=>{
          // console.log(item);
          let {chatType, chatId} = item || {};
          let {message_box_list} = this.$store.state;
          let data = {chatType, chatId}
          try{
            let res = await this.$http.request('/projectTask/deleteMessageBox', data);
            if(res.message == 'success'){
              if(message_box_list){
                let index = message_box_list.findIndex(unit => {
                  return unit.chatId == chatId && unit.chatType == chatType;
                })
                if(index != -1){
                  message_box_list.splice(index, 1);
                }
                // 如果删除的是当前的聊天则切换到上一级路由
                // if(chatId == this.$route.params.id && this.$route.name == 'fun_task_dialog_single'){
                if(chatId == this.$api.moduleTask.getRouteParamsId() && this.$route.name == 'fun_task_dialog_single'){
                  this.$router.push({name: 'fun_task_index'});
                }
              }
            }
          }catch(err){
            // console.log(err);
          }
        },
      })

      this.visible = false;
    },
    async setMessageTop(){
      let {messageData} = this;
      let {chatType, chatId, istop} = messageData;
      let res = await this.$api.setMessageTop({chatType, chatId, istop: istop?0:1});
      if(res){
        // 当前消息盒子滚动到顶部
        // this.$parent.scrollToCurrentTask(this.$route);
      }
      this.visible = !this.visible;
    },
    changeDoNotDistrub(){
      this.messageData.changeDoNotDistrub();
      this.visible = !this.visible;
    },

  }
}
</script>
<style lang="scss" scoped>
.contact_unit_wrapper{
  @include bbox;
  @include transition;
  @include flex;
  // @include transition;
  position: relative;
  z-index: 2;
  align-items: center;
  // width: 250px;
  width: 100%;
  padding-left: 17px;
  background-color: #fff;
  &.on, &:hover{
    background-color:#d4d4d4;
    .info_group{
      border-bottom: 1px solid transparent;
    }
  }
  &.top{
    background-color: #f4f4f4;
    &.on, &:hover{
      background-color:#d4d4d4;
      .info_group{
        border-bottom: 1px solid transparent;
      }
    }
  }
  .sticky{
    position: absolute;
    width: 14px;
    height: 14px;
    top: 0;
    right: 0;
  }
  .img_group{
    flex-shrink: 0;
    position: relative;
    .icon{
      position: absolute;
      top:-7px;
      left:-7px;
      z-index: 3;
      // width:16px;
      height:14px;
      background:rgba(255,59,48,1);
      border-radius:8px;
      font-size: 12px;
      line-height: 14px;
      padding:0 4px;
      color:#fff;
      &.do_not_distrub{
        top: -3px;
        left: -3px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        padding: 0;
      }
    }
    .img{
      width:44px;
      height:44px;
      border-radius:4px;
      display: block;
      background-color: $img_color;
    }
  }
  .info_group{
    @include bbox;
    @include flexsb;
    height: 44+22px;
    padding: 11px 0;
    // padding-right: 17px;
    flex-direction: column;
    align-items: flex-start;
    // margin-left:16px;
    width:100%;
    flex:1;
    overflow: hidden;
    border-bottom: 1px solid #f4f4f4;
    &.no_border{
      border-bottom: 1px solid transparent;
    }
    &.search{
      @include flex;
      justify-content: center;
    }
    .name_group{
      @include flexsb;
      width:100%;
      align-items: flex-start;
      .name{
        @include single_line_intercept;
        padding-top:3px;
        font-size:14px;
        
        font-weight:400;
        color:rgba(51,51,51,1);
      }
      .time{
        flex-shrink: 0;
        font-size:12px;
        padding-right: 17px;
        font-weight:400;
        color:rgba(153,153,153,1);
      }
    }
    .desc_group{
      @include flexsb;
      width:100%;
      .desc{
        flex:1;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width:129px;
        font-size:12px;
        
        font-weight:400;
        color:rgba(153,153,153,1);
        padding-right: 20px;
      }
      .do_not_distrub{
        @include background();
        width: 12px;
        height: 12px;
        margin-right: 4px;
        background: {
          image: url(~@/assets/images/dialog/do_not_distrub.png);
        }
      }
      .fun{
        background: {
          image: url('~@/assets/icon-More.png');
          size: 2px 10px;
          repeat: no-repeat;
          position: 4px center;
        }
        flex-shrink: 0;
        padding-right: 10px;
        width:10px;
        height: 16px;
      }
    }
  }
  
}
.fun_floor_group{
  @include bbox;
  // position: absolute;
  width:120px;
  line-height:50px;
  background:rgba(255,255,255,1);
  box-shadow:0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  // opacity:0.9;
  border-radius:4px;
  font-size:14px;
  
  font-weight:400;
  color:rgba(51,51,51,1);
  z-index: 9;
  padding-left: 16px;
  // top:50%;
  // right: 0px;
  // transform: translateY(-50%);
  .fun{
    @include bbox;
    // padding: 14px 0;
  }
}
</style>
<style>
.sidebar_contact_unit_popover_wrapper_el{
  margin:0;
  min-width: 0;
  padding:0;
  margin-left: 1px!important;
}
</style>